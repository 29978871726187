@use '@variables/colors' as *;

.emptyContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 130px;

    &--Tablet {
        padding: 15px 60px;
    }

    &--LargeMobile {
        padding: 15px 30px;
    }

    &--Mobile {
        padding: 15px 20px;
    }
}

.endOfContext {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    background-color: #fff;
    color: #595959;
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    margin: 16px 0;
}

.spaceCompact {
    height: 100%;
}

.spaceContainer {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    overflow: auto;
}

.emptyImg {
    color: $black-color;
}

.loadingRow {
    display: block;
    width: 100%;
    background: $white-color;
    padding: 15px 130px;

    &--Tablet {
        padding: 15px 60px;
    }

    &--LargeMobile {
        padding: 15px 30px;
    }

    &--Mobile {
        padding: 15px 20px;
    }
}

.withAvatarRow {
    color: $black-color;
    font-style: italic;
}

.loadingOutlined {
    margin-right: 10px;
    color: $black-color;
}
