@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.authPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
}

.authContent {
    flex-grow: 0;
    border-radius: 10px;
    padding: 60px 100px;
    background-color: $white-color;
}

.authForm {
    margin-top: 30px;
    width: 300px;

    & > * {
        margin-bottom: 0;
    }

    .formItem {
        margin-bottom: 0;

        .createButton {
            width: 100%;
            height: auto;
            margin-top: 20px;
            padding: 8px;
            font-size: 16px;
            font-weight: 600;
            border: none;
            background-color: $primary-color;
            color: $white-color;

            &:hover {
                background-color: $button-text-hover-color;
            }

            &:disabled {
                background-color: $primary-color;
                opacity: 0.5;
            }
        }

        .enterButton {
            width: 100%;
            font-weight: 600;
            color: $primary-color;

            &:hover {
                color: $button-text-hover-color;
            }
        }
    }
}

.title {
    display: flex;
    justify-content: center;
    margin: 0;
    color: $primary-color !important;
    font-size: 30px !important;
}

.icon {
    font-size: $font-size-nav;
    color: $primary-color;
}

.antInput {
    font-size: $font-size-text;
    padding: 10px 10px 10px 20px !important;
    color: $primary-color;
    border-color: $primary-color;
    margin-top: 20px;
}

.errorList {
    color: $error-color;
}
