@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.authPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
}

.title {
    display: flex;
    justify-content: center;
    margin: 0;
    color: $white-color !important;
    font-size: 30px !important;
}

.tabButton {
    padding: 5px;
    font-weight: $font-weight-medium;
    background-color: transparent;
    color: $background-color;

    &:hover {
        color: $button-text-hover-color !important;
        background-color: $primary-color !important;
    }
}

.tabButtonActive {
    padding: 5px;
    font-weight: $font-weight-medium;
    background-color: transparent;
    color: $background-color;
    text-decoration: underline;

    &:hover {
        color: $button-text-hover-color !important;
        background-color: $primary-color !important;
    }
}

.divider {
    width: 300px !important;
    min-width: 300px !important;
    border-color: $background-color !important;
    color: $background-color !important;
}

.serviceUrl {
    width: 100%;
    max-width: 300px;
    height: auto;
    padding: 8px;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    border: none;
    background-color: $white-color;
    color: $primary-color;
    margin-top: 20px;

    svg {
        line-height: 0;
    }

    &:hover {
        background-color: $button-text-hover-color !important;
    }
}

.serviceUrl:first-of-type {
    margin-top: 0 !important;
}
