.badgeCloseIcon {
    color: #6c61ed;
    cursor: pointer;
}

.promptTitle {
    max-width: 10rem;
    height: 25px;
    background: #fff;
    border-radius: 5px;
    padding: 0 5px;
}

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
