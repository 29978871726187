@use '@variables/fonts' as *;
@use '@variables/colors' as *;

.flex {
    justify-content: space-between;
    height: 100%;
    padding: 0 130px;

    &--Tablet {
        padding: 0 60px;
    }

    &--LargeMobile {
        padding: 0 30px;
    }

    &--Mobile {
        padding: 0 20px;
    }
}

.mobileFlex {
    width: 100%;
    height: 100%;
    padding: 0 20px;
}

.tabs {
    @media (width <= 610px) {
        flex-wrap: wrap;
        gap: 5px;
        justify-content: center;
    }
}

.title {
    font-weight: $font-weight-small !important;
    color: $primary-color !important;
    font-size: $font-size-header !important;
    line-height: $line-height-text !important;
    white-space: nowrap;
    margin: 0 !important;
}

.button {
    &:hover {
        color: $button-text-color !important;
        border-color: $button-text-color !important;
    }
}

.buttonActive {
    border-color: $primary-color !important;
    color: $white-color !important;
    background-color: $primary-color !important;
}

.divider {
    margin: 0;
}
