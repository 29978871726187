@use '@variables/fonts' as *;
@use '@variables/colors' as *;

.wrapper {
    min-width: 300px;

    @media (width < 350px) {
        min-width: 100%;
    }
}

.title {
    color: $button-text-color !important;
    font-weight: $font-weight-regular !important;
    margin-bottom: 35px !important;
    line-height: $line-height-text !important;
    padding: 0 !important;
}

.text {
    margin-bottom: 20px !important;
    line-height: $line-height-text !important;
    padding: 0 !important;
}
