@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.textArea {
    width: 96%;
    max-height: 100%;

    &::placeholder {
        color: $button-text-color;
    }
}
