@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.chatMenuItemWrapper {
    cursor: pointer;
    width: 100%;
    margin-bottom: 7.5px;
}

.chatMenuItemTitle {
    color: $white-color;
    line-height: $line-height-text;
    font-size: $font-size-item;
    font-weight: $font-weight-regular;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;

    &:hover {
        opacity: 0.6;
    }

    @media (width < 780px) {
        font-size: $font-size-nav;
    }

    &--Active {
        opacity: 0.3;

        &:hover {
            opacity: 0.3;
        }
    }
}

.deleteIcon {
    transition: all 0.5s;
    color: $white-color;
    font-size: 1.1rem;
    padding-left: 5px;

    &:hover {
        color: $error-color;
    }

    @media (width < 780px) {
        font-size: 1.2rem;
    }
}
