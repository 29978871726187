// Основные цвета
$primary-color: #131337; // Основной синий
$white-color: #fff; // Основной белый
$black-color: #000; // Основной чёрный

// Дополнительные цвета
$background-color: #ececec; // Цвет заднего фона
$user-message-color: #e5efff; // Цвет сообщения пользователя
$response-message-color: #c1daff; // Цвет сообщения с ответом
$button-text-color: #7c7ca0; // Цвет кнопок в виде текста
$button-text-hover-color: #2f80ed; // Цвет ховера кнопок в виде текста
$reserve-color: #2e3a8c;
$input-placeholder-color: #aaaeb3; // Цвет плейсхолдера
$error-color: #f00;
$input-border-color: #d9dae4;
