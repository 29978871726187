@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.answer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: $font-size-input;
}

.codeBlock {
    position: relative;
    width: 100%;

    pre {
        margin: 0;
        padding: 10px;
        border-radius: 8px;
        background-color: rgba($primary-color, 0.05);
        overflow: auto;
    }

    .copyButton {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: $white-color;
        color: $primary-color;
        padding: 4px 8px;
        font-size: $font-size-text;

        &:hover {
            background-color: $white-color;
            color: $button-text-hover-color;
        }
    }
}
