.formInputItem {
    margin: 0;
}

.submitButton {
    width: 100%;
    margin-top: 20px;
}

.changeRow {
    width: 100%;
}

.descrItem {
    margin: 0;
}
.questionIcon {
    margin-left: 5px;
    color: #bdbdbd;
}
.queryItem {
    margin: 0;
}
.buttonItem {
    margin: 0;
}
