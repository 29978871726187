@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.recentlyChatsWrapper {
    width: 100%;
    height: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.recentlyText {
    color: rgba($white-color, 0.4) !important;
    line-height: $line-height-text;
}

.recentlyChats {
    max-height: 100%;
    width: 100% !important;
    overflow: auto;
    mask-image: linear-gradient(to bottom, transparent, $white-color 10%, $white-color 90%, transparent);
    mask-image: linear-gradient(to bottom, transparent, $white-color 10%, $white-color 90%, transparent);

    &--onlyBottomShadow {
        mask-image: linear-gradient(to bottom, $white-color 10%, $white-color calc(100% - 30px), transparent);
        mask-image: linear-gradient(to bottom, $white-color 10%, $white-color calc(100% - 30px), transparent);
    }

    &--onlyTopShadow {
        mask-image: linear-gradient(to bottom, transparent, $white-color 10%, $white-color calc(100% - 30px));
        mask-image: linear-gradient(to bottom, transparent, $white-color 10%, $white-color calc(100% - 30px));
    }

    &::-webkit-scrollbar {
        display: none;
    }

    > * {
        width: 100%;
    }

    > * > * {
        display: flex;
        justify-content: space-between;
    }
}

.recentlyColumn {
    width: 100%;
}
