@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.layout {
    height: 100vh;
    display: flex;
}

.header {
    padding-inline: 0;
    width: 100%;
    background-color: $white-color;
    padding: 0;
    z-index: 2;
    height: 70px;
}

.content {
    height: 100%;

    .container {
        height: 100%;
        position: relative;
        background-color: $white-color;
    }
}

.footer {
    display: flex;
    align-items: end;
    height: 130px;
    background: $white-color;
    padding: 0 140px 40px 130px;

    @media screen and (width <= 800px) {
        padding-inline: 10px;
    }
}

.sider {
    background-color: $primary-color;
}

.mainLayoutInvisible {
    display: none;
}
