.wrapper {
    padding: 0 10px;
}
.messageRow {
    padding: 5px;
}
.input {
    background: #e9e9fc;
    width: auto;
    margin: 0 5px;
}
