@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.layout {
    height: 100vh;
    background-color: $primary-color;
}

.content {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    color: $background-color !important;
    text-align: center;
}

.button {
    padding: 8px;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    background-color: $white-color;
    color: $primary-color;

    &:hover {
        background-color: $button-text-hover-color !important;
    }

    &:disabled {
        background-color: $white-color !important;
    }
}
