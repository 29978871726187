@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.navSearchInput {
    background-color: rgba($white-color, 0.2) !important;
    border-radius: 100px;
    border: none;

    & > input {
        color: rgba($white-color, 0.7) !important;
        font-size: $font-size-text !important;
    }

    ::placeholder {
        color: rgba($white-color, 0.7) !important;
        font-size: $font-size-text;
    }

    .navSearchInputIcon {
        color: $white-color;
        margin-right: 18px;
    }
}

.drawerMenu {
    height: 100vh !important;
    background: $primary-color !important;
    padding: 0 10px 40px 15px;

    .customHeader {
        width: 100%;
    }

    @media (width <= 800px) {
        width: 100%;
    }
}

.siderClosedWrapper {
    height: 100%;
    padding: 15px 0;
}

.controls {
    height: 70px;
}

.newChatBtn {
    width: 100%;
    color: rgba($black-color, 0.9);
    font-size: $font-size-text;
}

.profile {
    background-color: rgba($white-color, 0.2);
    color: rgba($white-color, 0.9);
    border: none;
    margin-top: 10px;
    font-size: $font-size-text;
    width: 100%;
}

.balance {
    color: rgba($white-color, 0.9);
    padding: 7px 20px;
    text-align: center;
}

.logo {
    margin-top: 36px;
}

.title {
    color: $white-color !important;
    margin: 0 !important;
    font-size: 27.3px !important;
}

.hidden {
    display: none;
}
