@use '@variables/colors' as *;

.authPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
}

.errorIcon {
    color: $error-color;
    font-size: 70px;
}

.errorText {
    color: $background-color !important;
}

.loadText {
    color: $background-color !important;
}
