@use '@variables/colors' as *;

.errorText {
    color: $error-color;
}

.scrollContainer {
    width: 100%;
    height: 80vh;
    overflow: auto;
}
