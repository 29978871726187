.modal {
    .titleRow,
    .descriptionRow,
    .queryRow {
        padding-bottom: 10px;
    }

    .questionIcon {
        margin-left: 5px;
        color: #000000;
    }
}
