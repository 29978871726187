@use '@variables/colors' as *;
@use '@variables/fonts' as *;

$padding-horizontal: 5%;
$padding-vertical: 1rem;

.userContainer,
.notUserContainer {
    display: block;
    width: 100%;
    padding: 15px 130px;
    transition: all 0.5s;

    &--Tablet {
        padding: 15px 60px;
    }

    &--LargeMobile {
        padding: 15px 30px;
    }

    &--Mobile {
        padding: 15px 20px;
    }
}

.userContainer {
    background: rgba($primary-color, 0.05);
}

.notUserContainer {
    background: $white-color;
}

.modelTitle {
    padding: 5px 10px;
    border-radius: calc(1rem + 10px);
    background: rgba($primary-color, 0.1);
}

.messageCol {
    width: 100%;
    display: flex;
    flex-direction: column;

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #131337;

        th,
        td {
            border: 1px solid rgba(#131337, 0.05);
            padding: 8px;
            text-align: left;
        }

        th {
            background-color: rgba($primary-color, 0.1);
            font-weight: bold;
        }
    }

    img {
        max-width: 600px;
    }
}

.messageInput {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-size: $font-size-text;
    line-height: $line-height-text;
    color: $primary-color;
    background-color: rgba($primary-color, 0.05);
    resize: none;
    padding: 8px 12px;
    border-radius: 4px;

    &:hover,
    &:focus {
        background-color: rgba($primary-color, 0.08);
    }

    textarea {
        width: 100% !important;
        max-width: none !important;
        color: $primary-color;
    }
}

.messageActBtn {
    width: 20px !important;
    height: 20px;
    background-color: $white-color;
}

.messageEditBtn {
    width: 20px !important;
    height: 20px;
    padding: 0 !important;
    margin: 0 !important;
}

.controlsRow {
    margin-top: 10px;
    width: 100%;
    display: flex;
    gap: 20px;
}

.copyIcon {
    background-color: transparent !important;
    color: $primary-color;

    &:hover {
        color: $button-text-hover-color;
    }
}

.editButtonContainer {
    margin-top: 8px;
}
