@use '@variables/fonts' as *;
@use '@variables/colors' as *;

.wrapper {
    min-width: 300px;

    @media (width < 350px) {
        min-width: 100%;
    }
}

.title {
    color: $button-text-color !important;
    font-weight: $font-weight-regular !important;
    margin-bottom: 35px !important;
    line-height: $line-height-text !important;
}

.formItem {
    width: 100%;
    margin-bottom: 30px !important;
    line-height: 18px !important;
}

.antInput {
    font-size: $font-size-input;
    padding: 10px 10px 10px 20px !important;
    color: $primary-color;
    border-color: $input-border-color;
    margin: 0 !important;
}

.button {
    width: 100%;
    margin-top: 10px;
    padding: 8px;
    background-color: $primary-color;
    color: $white-color;
    line-height: $line-height-text !important;

    &:hover {
        background-color: $button-text-hover-color !important;
    }
}

.errorList {
    color: $error-color;
}
