@use '@variables/fonts' as *;
@use '@variables/colors' as *;

.headerRow {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 0 130px;

    &--Tablet {
        padding: 0 60px;
    }

    &--LargeMobile {
        padding: 0 30px;
    }

    &--Mobile {
        padding: 0 20px;
    }
}

.headerTitle {
    font-size: $font-size-header;
    line-height: $line-height-header;
    margin-bottom: 0 !important;
    padding: 0;
}

.divider {
    margin: 0;
}

.resetButton {
    height: 40px;
}
