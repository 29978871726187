.promptCardTitle {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.contentCol {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
}
.createPanel {
    width: 100%;
    padding: 1rem 5%;
}
.input {
    margin-right: 0.5rem;
    border-color: #6c61ed;
    background: #f0f0f0;

    &.ant-input-affix-wrapper-focused {
        border-color: #6c61ed;
    }

    &:focus {
        border-color: #6c61ed;
    }

    &:hover {
        border-color: #6c61ed;
    }

    & > input {
        background: #f0f0f0 !important;
    }

    .nav-search-input.ant-input-group-addon > button {
        background: #f0f0f0 !important;
        border-left: none;
    }

    .anticon-search {
        color: #6c61ed !important;
    }
}
.addButton {
    background: #f0f0f0;
    color: #6c61ed;
    border-color: #6c61ed;
    margin-top: 0.5rem;

    &:hover {
        background: #6c61ed;
        color: #fff !important;
        border: none;
    }
}
.cardsSectionWrapper {
    height: 90%;
}
.cardsSection {
    width: "100%";
    max-height: "85%";
    padding: 0 5%;
    overflow: auto;
}
.promptCard {
    cursor: pointer;
    width: 20rem;
    margin: 1rem 1rem;

    .ant-card-head {
        border: none;
    }

    .ant-card-actions {
        border: none;
    }
}
.promptCardActive {
    cursor: pointer;
    width: 20rem;
    margin: 1rem 1rem;
    border-color: #6c61ed !important;

    .ant-card-head {
        border: none;
    }

    .ant-card-actions {
        border: none;
    }
}

.cardDescription {
    min-height: 50px;
}
