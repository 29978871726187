@font-face {
    font-family: Inter;
    src: url('../Assets/fonts/Inter-VariableFont_opsz_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../Assets/fonts/Inter-Italic-VariableFont_opsz_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}

$font-family: 'Inter', sans-serif;
$font-size-title: 46px;
$font-size-header: 24px;
$font-size-nav: 20px;
$font-size-item: 18px;
$font-size-input: 16px;
$font-size-text: 14px;
$font-weight-small: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$line-height-title: 54px;
$line-height-header: 30px;
$line-height-nav: 28px;
$line-height-text: 22px;
