@use '@variables/fonts' as *;
@use '@variables/colors' as *;

.container {
    padding-inline: 65px;

    @media (width <= 530px) {
        padding-inline: 30px;
    }
}
