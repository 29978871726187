@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.modelsOptionsImage {
    width: 34px !important;
}

.modelsOptionsText {
    margin-left: 5px;
    color: $primary-color;
}

.modelSelector {
    width: 34px;
    min-width: 34px;
    border: none;

    > * {
        padding: 0 !important;
    }
}

.messageInput {
    position: absolute;
    bottom: 20px;
    left: 10%;
    width: 80%;
    min-height: 55px;
    max-height: 90px;
    transition:
        left 0.3s ease,
        padding 0.3s ease;
}

.inputWrapper {
    width: 100%;
    max-height: 90px;
    border: 1px solid $primary-color;
    border-radius: 40px;
    background-color: #fff;
    overflow: auto;
    padding: 10px;
}

.sendBtnWrapper {
    align-items: center;
}

.sendBtn {
    width: fit-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    color: $primary-color;
}

.sendBtnIcon {
    font-size: 24px !important;
}
