@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.wrapper {
    width: 100%;
    padding: 24px;
}

.title {
    text-align: center;
    margin-bottom: 0 !important;
}

.cardsContainer {
    width: 100%;
    max-width: 1200px;
    margin: 12px auto 0;
    justify-content: center;
    gap: 20px;
}

.card {
    box-sizing: border-box;
    width: 100%;
    min-width: 260px;
    height: 100%;
    padding: 24px;
    transition: all 0.3s;
    background: $white-color;
    border: 1px solid #131337;
    position: relative;
    border-radius: 8px;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 24px rgba($primary-color, 0.15);
    }
}

.popularBadge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: $primary-color;
    color: $white-color;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    z-index: 1;
}

.packageName {
    margin: 0 !important;
    color: $primary-color;
    font-weight: bold !important;
}

.amount {
    margin: 0 !important;
    color: $primary-color !important;
    font-size: $font-size-nav !important;
}

.button {
    width: 100%;
    max-width: 200px;
    height: 40px;
    font-size: $font-size-text;

    &:hover {
        transform: scale(1.05);
    }
}
