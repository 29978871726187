@use '@variables/colors' as *;
@use '@variables/fonts' as *;

.authPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
}

.authForm {
    width: 300px;

    .formItem {
        margin-bottom: 0;
    }

    .enterButton {
        width: 100%;
        height: auto;
        margin-top: 20px;
        padding: 8px;
        font-size: 16px;
        font-weight: $font-weight-semibold;
        border: none;
        background-color: $white-color;
        color: $primary-color;

        &:hover {
            background-color: $button-text-hover-color !important;
        }

        &:disabled {
            background-color: $white-color !important;
            opacity: 0.5;
        }
    }

    .textButton {
        width: 100%;
        padding: 0;
        font-weight: $font-weight-medium;
        color: $white-color;

        &:hover {
            color: $button-text-hover-color !important;
            background-color: $primary-color !important;
        }
    }
}

.title {
    display: flex;
    justify-content: center;
    margin: 0;
    color: $white-color !important;
    font-size: 30px !important;
}

.icon {
    font-size: $font-size-nav;
    color: $white-color;
}

.antInput {
    font-size: $font-size-text;
    padding: 10px 10px 10px 20px !important;
    background-color: $primary-color !important;
    color: $white-color !important;
    border-color: $white-color;
    margin-top: 20px;

    &:hover,
    &:focus {
        background-color: $primary-color;
        border-color: $button-text-hover-color;
    }

    &:focus-within {
        border-color: $button-text-hover-color;
    }

    input::placeholder {
        color: $white-color;
    }
}

.errorList {
    color: $error-color !important;
}

.space {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
